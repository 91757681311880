
import './App.css';

function App() {

  const pik = () => {
    window.location.href = "https://onelink.to/rtm4nd";
  }

  return (
    <div className="App">
      
      <header className="App-header">
        <img src='/logo v4.png' className="App-logo" alt="logo" />
        </header>
       <div className='button'>
        <button onClick={pik}>GET THE APP</button>
        </div> 
    
        <a
          className='App-link'
          href="http://v2-front.pikit.in/"
        >
          Continue to web
        </a>
        
      
    </div>
  );
}

export default App;
